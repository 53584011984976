<template>
 <!-- 用户注销 -->
 <div class="invoiceTitle" >
	 <van-nav-bar
	   title="用户注销"
	   left-arrow
	   @click-left="$router.go(-1)"
	 ></van-nav-bar>
	<div class="orderView" >
		<p class="orderView-title">进行中的订单</p>
		<div class="list" v-if="list" >
			<div class="item" v-for="item in list" >
				<div class="item-val">
					<div>
						<span>{{item.startConsultTime}} </span>
						<!-- <span class="item-val-name"> {{item.chatRoomName}}</span> -->
					</div>
					<div>
						<span>医生： {{item.doctorName}}（{{item.organizationName}}） </span>
					</div>
					<div class="money-view" >
						<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
					</div>
				</div>
			</div>
			<div v-if="list.length==0">
				无进行中的订单
			</div>
		</div>
		<p class="orderView-title">已支付的订单</p>
		<div class="list" v-if="list2" >
			<div class="item" v-for="item in list2" >
				<div class="item-val">
					<div>
						<span>{{item.startConsultTime}} </span>
						<!-- <span class="item-val-name"> {{item.chatRoomName}}</span> -->
					</div>
					<div>
						<span>医生： {{item.doctorName}}（{{item.organizationName}}） </span>
					</div>
					<div class="money-view" >
						<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
					</div>
				</div>
			</div>
			<div v-if="list2.length==0">
				无已支付的订单
			</div>
		</div>
	</div>
	<div class="buttons">
		<van-button type="danger" @click="removeAccount" round  class="btn" >账号注销</van-button>
	</div>
 </div>
</template>

<script>
import {NavBar,Button, Toast,Dialog} from "vant";

export default {
  name: "writeOff",
  components: {
	  [NavBar.name]:NavBar,
	  [Button.name]:Button
  },
  provide() {
    return {};
  },
  data() {
    return {
		list:null,
		list2:null,
	};
  },
  mixins: [],
  watch: {},
  created() {
	  this.getList(5)
	  this.getList(1)
  },
  mounted() {},
  methods: {
	  // 获取订单列表
	  getList(val) {
	    this.$http
	      .request("getOrderConsultList", {
	        patientId: this.$cache.local.getItem("patientId"),
	        orderStatus: val,
	      })
	      .then((res) => {
			  if(val==5){
				  this.list=res||[]
			  }else if(val==1){
				  this.list2=res||[]
			  }
	        
	      })
	      .catch((err) => {});
	  },
	  removeAccount(){
		  if(!this.list||!this.list2){
			  Toast("暂未获取到订单信息，请稍等")
			  return
		  }
		  if(this.list.length>0||this.list2.length>0){
			  Toast("存在进行中的订单，不能注销账号")
			  return
		  }
		    Dialog.confirm({
		      title: "温馨提示",
		      message: "您确定注销该账号吗？",
		      confirmButtonColor: "#1989fa",
		      confirmButtonText: "确定",
		    })
		      .then(() => {
		        this.$http
		          .request("removePatient", {id: this.$cache.local.getItem("patientId")})
		          .then((res) => {
		  			Toast({
						message:"注销成功",
						type:"success",
						onClose:()=>{
							this.$cache.local.removeItem("token");
							this.$cache.local.removeItem("patientId");
							this.$cache.local.removeItem("companyId");
							this.$cache.local.removeItem("addrId");
							this.$cache.local.removeItem("addrName");
							document.addEventListener('WeixinJSBridgeReady', function() {
							           WeixinJSBridge.call('closeWindow');
							       }, false);
							       WeixinJSBridge.call('closeWindow');
						}
					})
					
		             
		          })
		          .catch(() => {});
		      })
		      .catch(() => {});
	  },
	  // 返回价格
	  getServerPrice(n){
	  if(!n){
	  	return n
	  }
	  n=n/100;
	  return Number(n.toFixed(2))
	  },
  },
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">
.invoiceTitle{
	height: 100vh;
}
.orderView{
	height: calc(100% - 120px);
	box-sizing: border-box;
	margin:auto;
	overflow: auto;
	.orderView-title{
		font-size: 14px;
		font-weight: 600;
		line-height: 30px;
		margin: 10px 0;
		background-color: #f6f6f6;
		padding-left: 10px;
	}
}
.list{
	// height: calc(100% - 50px);
	overflow: auto;
	font-size: 14px;
	box-sizing: border-box;
	padding:0 10px;
	.item{
			padding: 10px;
			border-bottom: 1px solid #ccc;
			font-size: 14px;
			line-height: 28px;
			.money-view{
				text-align: right;
				.money{
					font-weight: 600;
					color: #00a8ff;
				}
			}
			.item-val{}
			
			
		}
}
.buttons{
	text-align: center;
	margin: 10px 0;
	.btn{
		width: 70%;
	}
}
</style>
